import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useHistory } from 'react-router-dom';
import {getLocations} from "../HTTPS/apisInterface";

export default function (props) {
	const history = useHistory();
	const renderLocation = (permalink)=>{
		history.push(`/locations/${permalink}/booking${history.location.search}`);
	}
	const [images,setImages] = useState([]);

	useEffect( ()=>{
		let newImages = [];
	 getLocations().then(response=>{
		 let width = response.length * 10
		 if(response.length === 1){
			 width = '50'
		 }
		 response.map(item=>{
			 newImages.push({
				 url: item.image,
				 title: item.name,
				 width:`${width}%`,
				 permalink:item.permalink,
				 renderFunction : renderLocation
			 })
		 })
		 setImages(newImages);
		});

	},[])



	return(<React.Fragment>
		<BodyContainer {...props} childComponent={<React.Fragment>
			<React.Fragment>
				<div className={'col'}>
					<h4>Waar word je graag verder geholpen?</h4>
				</div>
				<CustomButton carCondition={props.brandCondition} className={'margin-images'}  images={images}/>
			</React.Fragment>
		</React.Fragment>
		} />
	</React.Fragment>)
}
