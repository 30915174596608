import React, {useEffect, useState} from 'react';
import './App.scss';
import  {
  BrowserRouter ,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import NotFound from "./Components/NotFound/NotFound";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import Brand from "./Components/Brand/Brand";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './global';
import {carBrands} from "./AppConstants";


import { getFooterData} from "./Components/HTTPS/apisInterface";
import Form from "./Components/Form/Form";
import ReactGA from 'react-ga';

import Location from "./Components/Locations/Location";
import BookingSystem from "./Components/BookingSystem/BookingSystem";
function App() {

  const [theme, setTheme] = useState('light');
  const [brands,setBrands] = useState([]);
  const [homePageText,setHomePageText] = useState(null);
  const [currentBrand,setCurrentBrand] = useState(null);
  const [footerBrandData,setFooterBrandData]= useState(null);
  const [isRedirectURL,setIsRedirectUrl] = useState(null);

  const getCurrentBrandData = (location,locationSearch = null)=>{
    setIsRedirectUrl(new URLSearchParams(locationSearch).get('car_url'))
    location = location.substr(1,).split('/');
    const currentBrand = brands.filter(item=>location[0] === item.name.toLowerCase());

    if(currentBrand.length > 0){
      currentBrand[0].condition = location[1];
      currentBrand[0].link = currentBrand[0].name.toLowerCase();

      if(location.length > 3){
        currentBrand[0].currentCarName = location[3];
      }

      if(location.length >= 3){
        let currentExactLocation = currentBrand[0].locations.filter(item=>{
          return item.permalink === location[2];
        })
        if(currentExactLocation.length === 0 && location[1] === "fleet"){
          for(let i = 0;i < brands.length;i++){
            if(brands[i].name == "MINI"){
              currentExactLocation =  brands[i].locations.filter(item=>item.permalink === location[2])
            }
          }
        }
        if(currentExactLocation.length === 0 ){
          return null;
        }
        currentBrand[0].currentLocation = currentExactLocation[0];
        currentBrand[0].currentLocationFullName = currentExactLocation[0].name;
      }
      setCurrentBrand(currentBrand[0]);
      if(footerBrandData === null){
        getFooterData(currentBrand[0].name).then(response=>{
          setFooterBrandData(response);
        });
      }

      return currentBrand[0];
    }
    else{
      setCurrentBrand(null);
      return null;
    }

  }
  return (
     <React.Fragment>
       <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
         <GlobalStyles />
         <BrowserRouter>
           <Header brand={currentBrand}/>
           <Route
              render={({location})=>{
                const { pathname } = location;
                if(pathname.includes(carBrands.mini.link)){
                  setTheme('dark')
                }
                else{
                  setTheme('light')
                }
                return (
                   <Switch location={location}>
                     <Route exact path="/" render={({})=>{
                       setFooterBrandData(null);
                       return <HomePage reactGA={ReactGA}  />;
                     }}>
                     </Route>
                     <Route  exact path="/locations" render={ ({location})=>{
                       return <Location reactGA={ReactGA} switchTheme={setTheme}/>;
                     }}>
                     </Route>
                     <Route  exact path="/locations/:brandLocation/booking" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);
                       return <BookingSystem reactGA={ReactGA}   />
                     }}/>
                     <Route  exact path="/locations/:brandLocation/booking/form" render={ ({location})=>{
                       ReactGA.pageview(window.location.pathname + window.location.search);

                       return <Form reactGA={ReactGA} urlSearchParam={isRedirectURL}  {...location.state}/>
                     }}/>
                     <Route path="*" component={NotFound}/>

                   </Switch>)
              }}
           />
           <Footer socialMedia={currentBrand} brand={footerBrandData} theme={theme}/>
         </BrowserRouter>
       </ThemeProvider>
     </React.Fragment>
  );
}

export default App;
