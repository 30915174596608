import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import {CSSTransition} from "react-transition-group";
import Button from "@material-ui/core/Button";
import {carBrands} from "../../AppConstants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Markup from "interweave";

export default function (props) {
  const history = useHistory();

  const isMobile = useMediaQuery('(max-width:769px)');
  return(<React.Fragment>
       <div style={{paddingTop:isMobile ? '0':'80px'}}  className={"homepage-content white"}>
         <div className={"container"}>
           <div className={"row"}>

                <div className={"col-lg-12 col-md-12 col-sm-12 brands-container"}>

             <CSSTransition
                in={true }
                timeout={900}
                classNames="list-transition"
                appear
                unmountOnExit
             >
               <div style={{paddingTop:'80px'}} key={props.brand} className={"row"}>
                 <div key={'maakAfspraak'} className={'col-lg-12 col-md-12 col-sm-12'}>
                   <div style={{height:isMobile? '200px':'230px'}} className={'logo-container'}>
                     <div style={{height:isMobile?'100px':'150px'}} className={'logo-container-image'}>
                       <img   src={'/assets/img/afspraakmaken.jpeg'} />
                     </div>
                     <Button className={`mini-button`} onClick={()=>{

                       history.push(`/locations`)}} style={{cursor:'pointer',fontFamily:'obvia,sans-serif'}}>Maak afspraak</Button>
                   </div>
                 </div>
               </div>
             </CSSTransition>
             </div>
           </div>
         </div>
       </div>
     </React.Fragment>
  )
}
