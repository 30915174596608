import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import DriveEtaSharpIcon from "@material-ui/icons/DriveEta";
import {useHistory , useParams} from 'react-router-dom';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CommuteIcon from '@material-ui/icons/Commute';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {carBrands} from "../../../AppConstants";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NoteIcon from '@material-ui/icons/Note';
import VideocamIcon from '@material-ui/icons/Videocam';
export default  function (props) {
	const history = useHistory();
	const links = history.location.pathname.substr(1,).split("/");
	let { brandLocation } = useParams();
	return (
		 <div className={"brand-container"}>
			 <div style={{backgroundImage:`url(/assets/img/vantrier-header.jpeg)`}} className={`brand-banner`}>
			 </div>
			 <div className={'container container-middle'}>
				 <div className={'row'}>
					 <div className={'col-lg-12 col-md-12 col-sm-12'}>
						 <Breadcrumbs style={{paddingTop:'10px'}}  separator={<NavigateNextIcon style={{color:`#0C2E40`}} fontSize="small" />} aria-label="breadcrumb">
							 <Link color="inherit" style={{color:`#0C2E40`}}  onClick={()=>{history.push(`/${history.location.search}`)}}  className={`link anchorTag`}>
								 <HomeIcon className={'icon'} />
								 Van Trier
							 </Link>
							 {links.map((item,index)=>{
							 	if(index === 0){
									return  <Link style={{color:`${links.length === index + 1 ?'unset': `#0C2E40`}`}}  key={item-index}   color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/locations`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<LocationOnIcon className={'icon'}/>
										Locations
									</Link>
								}
							 	if(index === 1){
									return  <Link  style={{color:`${links.length === index + 1 ?'unset': `#0C2E40`}`}} key={item-index}  color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/locations/`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										{item === "new" ? <FiberNewIcon className={'icon'}/> : item === "used" ? <AutorenewIcon className={'icon'}/> : <CommuteIcon className={'icon'}/> }
										{item.charAt(0).toUpperCase()+ item.slice(1)}
									</Link>
								}
							 	if(index === 2 && props.location){

								return 	<Link style={{color:`${links.length === index + 1 ?'unset': `#0C2E40`}`}}   key={item-index}  color="inherit"
										onClick={()=>{
									if(index+1 === links.length){
										return;
									}
									history.push(`/`)}}
									className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									{props.location.permalink === "videocall" ?<VideocamIcon className={'icon'}/> :	<LocationOnIcon className={'icon'}/>}
										{props.location.name.charAt(0).toUpperCase() + props.location.name.slice(1)}

									</Link>
								}
							 	if(index === 4){
									return <Link style={{color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.bmw.title ? '#0C2E40' :'#EC9603'}`}`}}   key={item-index} color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/locations/${brandLocation}/${props.location.permalink}/${item}${history.location.search}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<NoteIcon className={'icon'}/>
										Form
									</Link>
								}
							 	return <Link   style={{color:`${links.length === index + 1 ?'unset': `#0C2E40`}`}}  key={item-index} color="inherit" onClick={()=>{
									if(index+1 === links.length){
										return;
									}

							 		history.push(`/locations/${brandLocation}/booking`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									<DriveEtaSharpIcon className={'icon'}/>
									{item}
								</Link>

							 })}
						 </Breadcrumbs>
					 </div>
				 </div>
				 <div style={{paddingTop:'10px'}} className={'row'}>
					 {props.childComponent}
				 </div>
			 </div>

		 </div>
	)
}
